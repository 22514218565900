import { FluidObject } from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

export interface FluidImage {
  base: string;
  relativePath: string;
  childImageSharp: { fluid: FluidObject };
}
export function findFluidImage(relativePath: string): FluidImage | undefined {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          sourceInstanceName: { eq: "assets" }
        }
      ) {
        nodes {
          base
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);
  const imageNodes: FluidImage[] = data.allFile.nodes;
  const result = imageNodes.find(n => n.relativePath === relativePath);
  if (!result)
    console.warn("Could not find image for relativePath ", relativePath);
  return result;
}
