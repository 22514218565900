import React, { FC } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#___gatsby");

interface ModalStateClasses {
  base: string;
  afterOpen: string;
  beforeClose: string;
}

interface ModalProps {
  isOpen: boolean;
  onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
  overlayClassName?: string | ModalStateClasses;
  containerClassName?: string | ModalStateClasses;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  overlayClassName,
  containerClassName,
  children,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    overlayClassName={overlayClassName}
    className={containerClassName}
  >
    {children}
  </ReactModal>
);
