import React, { FC } from "react";
import classNames from "classnames/bind";
import Img from "gatsby-image";

import { findFluidImage } from "../../utils/imageHelpers";
import styles from "./ImageGrid.module.scss";

const cx = classNames.bind(styles);

type ImageType = string;

interface TileProps {
  image: ImageType;
  onClick(event: React.MouseEvent): void;
}
const Tile: FC<TileProps> = ({ image, onClick }) => (
  <div className={cx("Tile")} onClick={onClick}>
    <Img fluid={findFluidImage(image)!.childImageSharp.fluid} />
  </div>
);

interface ImageGridProps {
  images: ImageType[];
  onImageClick(index: number, event: React.MouseEvent): void;
  columns?: number;
  gridGap?: string;
}
export const ImageGrid: FC<ImageGridProps> = ({ images, onImageClick }) => {
  const handleClick = (idx: number) => (e: React.MouseEvent) => {
    onImageClick(idx, e);
  };
  return (
    <div className={cx("ImageGrid")}>
      {images.map((i, idx) => (
        <Tile image={i} onClick={handleClick(idx)} key={i} />
      ))}
    </div>
  );
};
