import React, { FC } from "react";
import styled from "styled-components";

import { ExternalLink } from "./index";

export const NewletterSignup: FC = () => (
  <Box>
    <Paragraph>
      Get notified about new blog posts?{" "}
      <SignupLink href="https://tinyletter.com/lehnerpat" newTab>
        Sign up to my email newsletter!
      </SignupLink>
    </Paragraph>
    <Paragraph>
      Über neue Blog Posts Bescheid wissen?{" "}
      <SignupLink href="https://tinyletter.com/lehnerpat" newTab>
        Abonniere meinen Email-Newsletter!
      </SignupLink>
    </Paragraph>
  </Box>
);

const Box = styled.div`
  padding: 0.5rem;
  border: 1px solid #ebeaea;
  margin: 2rem 0;
  text-align: center;
  line-height: 1.4;
`;

const Paragraph = styled.p`
  & + & {
    margin-top: 0.4rem;
  }
`;

const SignupLink = styled(ExternalLink)`
  white-space: nowrap;
`;
