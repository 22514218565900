import React, { FC } from "react";
import classNames from "classnames/bind";
import Img from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import ScrollLock from "react-scrolllock";

import { Modal } from "../Modal";
import { findFluidImage } from "../../utils/imageHelpers";

import styles from "./Lightbox.module.scss";
const cx = classNames.bind(styles);

function lightboxImageItemClass(index: number, currentIndex: number): string {
  if (index === currentIndex) return cx("Visible");
  if (index > currentIndex)
    return cx("Future", { Next: index === currentIndex + 1 });
  if (index < currentIndex)
    return cx("Past", { Previous: index === currentIndex - 1 });
  return "";
}

interface HeaderButtonProps {
  onClick?(event: React.MouseEvent): void;
}
const HeaderButton: FC<HeaderButtonProps> = ({ onClick, children }) => (
  <button type="button" onClick={onClick} className={cx("HeaderButton")}>
    {children}
  </button>
);

interface LightboxProps {
  isVisible: boolean;
  onRequestClose(event: React.MouseEvent | React.KeyboardEvent): void;
  onRequestNext(event: React.MouseEvent | React.KeyboardEvent): void;
  onRequestPrevious(event: React.MouseEvent | React.KeyboardEvent): void;
  images: string[];
  currentIndex: number;
}
export const Lightbox: FC<LightboxProps> = ({
  isVisible,
  onRequestClose,
  onRequestNext,
  onRequestPrevious,
  images,
  currentIndex,
}) => {
  const Header = () => (
    <div className={cx("Header")}>
      <HeaderButton onClick={onRequestClose}>
        <FontAwesomeIcon
          icon={faTimes}
          size="2x"
          color="rgba(255,255,255,0.75)"
        />
      </HeaderButton>
    </div>
  );
  const Footer = () => <></>; // <div className={cx("Footer")}>(Footer)</div>;
  const NextButton = () => (
    <button type="button" className={cx("NextButton")} onClick={onRequestNext}>
      <FontAwesomeIcon
        icon={faChevronRight}
        size="2x"
        color="rgba(255,255,255,0.75)"
      />
    </button>
  );
  const PrevButton = () => (
    <button
      type="button"
      className={cx("PrevButton")}
      onClick={onRequestPrevious}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        size="2x"
        color="rgba(255,255,255,0.75)"
      />
    </button>
  );
  const isFirst = currentIndex === 0;
  const isLast = currentIndex === images.length - 1;

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      containerClassName={cx("ModalContainer")}
      overlayClassName={cx("ModalOverlay")}
    >
      <ScrollLock>
        <div className={styles.Slideshow}>
          <ul>
            {images.map((i, idx) => (
              <li key={i} className={lightboxImageItemClass(idx, currentIndex)}>
                <Img
                  fluid={findFluidImage(i)!.childImageSharp.fluid}
                  imgStyle={{ objectFit: "contain" }}
                  placeholderStyle={{ objectFit: "contain" }}
                  style={{ maxHeight: "85vh" }}
                />
              </li>
            ))}
          </ul>
          <Header />
          <Footer />
          {!isFirst && <PrevButton />}
          {!isLast && <NextButton />}
        </div>
      </ScrollLock>
    </Modal>
  );
};
