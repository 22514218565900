module.exports = [{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-mdx-virtual-bf369a6c19/0/cache/gatsby-plugin-mdx-npm-1.0.70-5808ea0bbb-2.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/.yarn/$$virtual/gatsby-remark-images-virtual-53ec4bbbfb/0/cache/gatsby-remark-images-npm-3.1.44-e84d1c4438-2.zip/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.44","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-prismjs","gatsby-remark-copy-linked-files","gatsby-remark-smartypants"]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-remark-images-virtual-53ec4bbbfb/0/cache/gatsby-remark-images-npm-3.1.44-e84d1c4438-2.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-manifest-virtual-3a81897c3f/0/cache/gatsby-plugin-manifest-npm-2.2.41-1473ed8613-2.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Patrick's Japan 2020 Blog","short_name":"Japan 2020","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/japanese-flag.png"},
    },{
      plugin: require('../.yarn/$$virtual/gatsby-plugin-netlify-cms-virtual-16438555aa/0/cache/gatsby-plugin-netlify-cms-npm-4.1.38-053ef87e99-2.zip/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false,"publicPath":"netlify-cms"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-plugin-typography-virtual-51868244f8/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
