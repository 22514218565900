import React, { FC } from "react";
import Img from "gatsby-image";
import { findFluidImage } from "../utils/imageHelpers";

interface ImageProps {
  assetSrc: string;
  alt?: string;
}
export const Image: FC<ImageProps> = ({ assetSrc, alt }) => (
  <Img fluid={findFluidImage(assetSrc)!.childImageSharp.fluid} alt={alt} />
);
