import React, { FC, useState } from "react";

import { Lightbox } from "./Lightbox";
import { ImageGrid } from "./ImageGrid";

interface GalleryProps {
  images: string[];
}
export const Gallery: FC<GalleryProps> = ({ images }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };
  const openLightboxAt = (index: number) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  };

  const goToNextImage = () =>
    setCurrentIndex(currentIndex =>
      currentIndex === images.length - 1 ? currentIndex : currentIndex + 1,
    );
  const goToPreviousImage = () =>
    setCurrentIndex(currentIndex =>
      currentIndex === 0 ? currentIndex : currentIndex - 1,
    );

  return (
    <>
      <Lightbox
        isVisible={isLightboxOpen}
        onRequestClose={closeLightbox}
        images={images}
        currentIndex={currentIndex}
        onRequestNext={goToNextImage}
        onRequestPrevious={goToPreviousImage}
      />
      <ImageGrid images={images} onImageClick={idx => openLightboxAt(idx)} />
    </>
  );
};
