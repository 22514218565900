import React, { FC, CSSProperties } from "react";

interface PlaceholderMessageProps {}

const placeholderMessageStyle: CSSProperties = {
  textAlign: "center",
  fontSize: "150%",
  padding: "3em 1em",
};

export const PlaceholderMessage: FC<PlaceholderMessageProps> = ({
  children,
}) => <div style={placeholderMessageStyle}>{children}</div>;
