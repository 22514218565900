import auth0 from "auth0-js";
import { navigate } from "gatsby";

const isBrowser = typeof window !== "undefined";

const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;
const auth0ClientId = process.env.GATSBY_AUTH0_CLIENTID;

const required = (name: string, value: string | null | undefined): string => {
  if (!value)
    throw new Error(`Required value for ${name} is empty or undefined`);
  return value;
};

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: required("GATSBY_AUTH0_DOMAIN", auth0Domain),
      clientID: required("GATSBY_AUTH0_CLIENTID", auth0ClientId),
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: "token id_token",
      scope: "openid",
    })
  : null;

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: 0,
};

let user = {};

export const isAuthenticated = () => {
  if (!isBrowser) {
    return;
  }

  return (
    localStorage.getItem("isLoggedIn") === "true" &&
    !!tokens.accessToken &&
    !!tokens.idToken
  );
};

const LoginSuccessNavigationPathKey = "LoginSuccessNavigationPath";

export const login = (loginSuccessNavigationPath?: string) => {
  if (!isBrowser) {
    return;
  }

  if (!!loginSuccessNavigationPath)
    localStorage.setItem(
      LoginSuccessNavigationPathKey,
      loginSuccessNavigationPath,
    );
  else localStorage.removeItem(LoginSuccessNavigationPathKey);

  auth?.authorize();
};

const setSession = (cb = () => {}) => (err: any, authResult: any) => {
  if (authResult && authResult.accessToken && authResult.idToken) {
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    tokens.accessToken = authResult.accessToken;
    tokens.idToken = authResult.idToken;
    tokens.expiresAt = expiresAt;
    user = authResult.idTokenPayload;
    localStorage.setItem("isLoggedIn", "true");
    cb();
  } else {
    // log "err" to debug here
    navigate("/");
    cb();
    return;
  }
};

export const silentAuth = (callback: () => void) => {
  if (!isAuthenticated()) return callback();
  auth?.checkSession({}, setSession(callback));
};

export const handleAuthentication = () => {
  if (!isBrowser) {
    return;
  }

  auth?.parseHash(
    setSession(() => {
      const loginSuccessNavigationPath = localStorage.getItem(
        LoginSuccessNavigationPathKey,
      );
      if (!!loginSuccessNavigationPath) {
        localStorage.removeItem(LoginSuccessNavigationPathKey);
        return navigate(loginSuccessNavigationPath);
      }
      return navigate("/");
    }),
  );
};

export const getProfile = () => {
  return user;
};

export const logout = () => {
  if (typeof localStorage !== "undefined")
    localStorage.setItem("isLoggedIn", "false");
  auth?.logout({});
};
