import React, { FC, HTMLAttributes } from "react";

interface ExternalLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href?: string;
  newTab?: boolean;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  href,
  newTab,
  ...defaultProps
}) => (
  <a
    href={href}
    target={newTab ? "_blank" : "_self"}
    rel="noopener noreferrer"
    {...defaultProps}
  />
);
