import React, { FC } from "react";

export const EnglishFlag: FC = () => <>🇬🇧</>;
export const GermanFlag: FC = () => <>🇩🇪</>;
export const JapaneseFlag: FC = () => <>🇯🇵</>;

const HeadlineTag = "h2";
interface HeadlineProps {
  children: string;
}
export const EnglishHeadline: FC<HeadlineProps> = ({ children }) => (
  <HeadlineTag style={{ textAlign: "center" }}>
    <EnglishFlag /> {children}
  </HeadlineTag>
);
export const GermanHeadline: FC<HeadlineProps> = ({ children }) => (
  <HeadlineTag style={{ textAlign: "center" }}>
    <GermanFlag /> {children}
  </HeadlineTag>
);
